import CollapsibleButton from "components/CollapsibleButton";
import { Icon } from "components/Icon";
import Link from "components/Link";
import { Title } from "components/Title";
import { Authors } from "components/article/header/Authors";
import { DateTexts } from "components/article/header/DateText";
import QRCode from "components/article/header/QRCode";
import UpdatesPopover from "components/article/header/UpdatesPopover";
import useUser from "hooks/useUser";
import { t } from "locales";
import { useState } from "react";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { IPage } from "data/contentData/api/content";
import { useFavorites } from "data/contentData/hooks/user.hooks";
import getContentIds from "lib/get-sources-ids";
import { toLocalDate } from "lib/string/date-to-locale-date-string";
import usePath from "hooks/usePath";
type TabType = "qr" | "update";
interface IProps {
  page: IPage;
}
export function Header({
  page
}: IProps) {
  const {
    title,
    updated,
    revised,
    published,
    revisionInterval,
    authors,
    ingress,
    byline,
    template,
    qrCodeUrl,
    updates,
    source
  } = page;
  const {
    authorsContentId
  } = getContentIds(page);
  const url = usePath();
  const {
    favorite,
    toggle
  } = useFavorites(url);
  const {
    authenticated,
    user
  } = useUser();
  const [tabValue, setTabValue] = useState<TabType | null>(null);
  const handleClick = (value: TabType) => () => {
    setTabValue(prev => prev === value ? null : value);
  };
  return <StyledContainer container data-sentry-element="StyledContainer" data-sentry-component="Header" data-sentry-source-file="Header.tsx">
            <Grid item fontWeight={700} pt={1} component={Title} id={title ?? "article-id"} xs={12} data-sentry-element="Grid" data-sentry-source-file="Header.tsx">
                {title ?? <Skeleton height={72} />}
            </Grid>
            {ingress && <Grid item xs={12}>
                    <Typography variant="body1" fontWeight={700}>
                        {ingress}
                    </Typography>
                </Grid>}

            {byline && <Grid item xs={12}>
                    <Typography variant="body1">{byline}</Typography>
                </Grid>}

            <DateTexts updated={updated} revised={revised} published={published} revisionInterval={revisionInterval} data-sentry-element="DateTexts" data-sentry-source-file="Header.tsx" />
            <Authors authors={authors} authorsContentId={authorsContentId} data-sentry-element="Authors" data-sentry-source-file="Header.tsx" />
            {updates && <UpdatesPopover updates={updates} />}
            <StyledProductAndPrinter item xs={12} data-sentry-element="StyledProductAndPrinter" data-sentry-source-file="Header.tsx">
                <Typography data-sentry-element="Typography" data-sentry-source-file="Header.tsx">
                    {`${t["on-print"]["print-from-product"]} ${t["product-name"]}, ${toLocalDate(new Date(), process.env.NEXT_PUBLIC_LOCALE)}`}
                    {user?.name && ` - ${t["on-print"]["printed-by"]} ${user.name}`}
                </Typography>
            </StyledProductAndPrinter>
            <Grid className="no-print" item component={Divider} xs={12} data-sentry-element="Grid" data-sentry-source-file="Header.tsx" />
            <Grid item className="no-print" component={CollapsibleButton} icon={<Icon name="print" />} onClick={() => window.print()} data-sentry-element="Grid" data-sentry-source-file="Header.tsx">
                {t.print}
            </Grid>
            <Grid item className="no-print" component={CollapsibleButton} icon={favorite ? <Icon name="favorite-added" /> : <Icon name="favorite-removed" />} onClick={() => title && toggle({
      url,
      name: title,
      template: template?.name ?? ""
    })} disabled={!authenticated} data-sentry-element="Grid" data-sentry-source-file="Header.tsx">
                {!!favorite ? t["favorite"]["remove-from-favorites"] : t["favorite"]["add-to-favorites"]}
            </Grid>

            {qrCodeUrl && <Grid item className="no-print" component={CollapsibleButton} icon={<Icon name="qr" />} onClick={handleClick("qr")}>
                    {t["share-article"]}
                </Grid>}

            {qrCodeUrl && <Grid item xs={12} mt={0}>
                    <QRCode show={tabValue === "qr"} url={qrCodeUrl} />
                </Grid>}

            <Grid className="no-print" item component={Divider} xs={12} data-sentry-element="Grid" data-sentry-source-file="Header.tsx" />

            {source && <Typography>
                    {t["article"]["source-article-link"]}{" "}
                    <Link href={source.url}>{source.name}</Link>
                </Typography>}
        </StyledContainer>;
}
const StyledContainer = styled(Grid)(({
  theme
}) => {
  const {
    breakpoints,
    spacing
  } = theme;
  return {
    justifyContent: "space-evenly",
    padding: spacing(0, 2, 0),
    gap: spacing(1),
    [breakpoints.up("sm")]: {
      "& > button": {
        margin: spacing(0, 1)
      },
      backgroundColor: "inherit",
      color: "inherit",
      justifyContent: "initial",
      padding: 0
    },
    "& > hr": {
      margin: spacing(1, 0)
    }
  };
});
const StyledProductAndPrinter = styled(Grid)(() => ({
  display: "none",
  "@media print": {
    display: "inherit"
  }
}));